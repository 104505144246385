import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
    displayErrorMessage,
    ErrorResponse, formatNullableRank,
    formatNullableScore, getCookie,
    HTTP_CLIENT,
    HTTP_CLIENT_JQUERY_ADAPTER
} from "./Util"
import { ErrorState, ServerError, Settings } from "./models";
import { Context, ErrorContext, SettingsContext, UserContext } from "./Context";
import { Outlet, useLocation } from "react-router-dom";
import { ApplicationLink } from './Routing';
import { LeaderBibs, Reveal } from "./ReactComponents";
import { usePageTracking, useSignOut } from "./Hooks";
import { RecaptchaProvider } from "./Provider";
import * as Sentry from "@sentry/react";

export const SiteBase = (props: SiteBaseProps) => {

    usePageTracking();

    const logo = props.logo;
    return <>
        <header className='site-header'>
            { logo }
            { props.mainMenu }
            <UserWidget />
        </header>
        <ErrorReveal />
        <CookieBanner />
        <div className="row column mainContent">
            <Messages />
            <ErrorBoundary errorPage={props.errorPage}>
                <RecaptchaProvider>
                    <Outlet />
                </RecaptchaProvider>
            </ErrorBoundary>
        </div>
        { props.footer }
        { props.subFooter }
    </>
}

export const AppLogo = ({logoPath}: {logoPath: string}) => <div className={'logo'}>
    <div className="banner">
        <div>
        </div>
    </div>
    <ApplicationLink href={'/'}>
        <img src={logoPath} alt="home"/>
    </ApplicationLink>
</div>

interface SiteBaseProps {
    mainMenu: React.ReactElement;
    footer: React.ReactElement;
    subFooter: React.ReactElement;
    errorPage: React.ReactElement;
    logo: React.ReactElement;
}

const ErrorReveal = () => {

    const [visible, setVisible] = useState<boolean>(false);
    const [text, setText] = useState<string>("");
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

    useEffect(() => {
        HTTP_CLIENT.addErrorHandling((error: ErrorResponse<ServerError>) => {
            if(error.isClientError()) {
                if(error.config?.method?.toUpperCase() === 'POST' || error.config?.method?.toUpperCase() === 'PUT' || error.config?.method?.toUpperCase() === 'PATCH') {
                    const errorObject = error.response?.data as any;
                    if(errorObject && errorObject.message) {
                        displayErrorMessage(errorObject.message)
                    }
                    else {
                        displayErrorMessage("We're sorry. We could not do that. Please try again later")
                    }
                }
            }
        });
    }, []);

    const close = useCallback(() => {
        if(timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(undefined);
        }
        setVisible(false);
    }, [timeoutId]);

    useEffect(() => {
        const listener = (event: Event) => {
            setText((event as ErrorMessage).detail.message);
            setTimeoutId(setTimeout(() => close(), 10_000));
            setVisible(true);
        };
        document.addEventListener('errorMessage', listener);
        return () => document.removeEventListener('errorMessage', listener);
    }, [close]);

    return <Reveal visible={visible} closeRequest={() => close()} theme="alert">
        <p>{text}</p>
    </Reveal>;
}

const CookieBanner = () => {

    const accepted = getCookie('cookieConsent') === 'true';

    const [visible, setVisible] = useState<boolean>(!accepted);
    const settings = useContext<Settings | undefined>(SettingsContext);

    function acceptCookies() {
        HTTP_CLIENT_JQUERY_ADAPTER.post({
            url: '/api/set_cookie',
            contentType: 'application/json',
            data: {name: 'cookieConsent', value: 'true'}
        });
        setVisible(false);
    }

    if(!settings) {
        return <React.Fragment />;
    }

    return <Reveal visible={visible} theme="primary">
        <span>
            <span style={{marginRight: '1em'}}> {settings?.display_name} is using cookies to give you an optimal user experience</span>
            <button onClick={() => acceptCookies()} style={{marginTop: '0.5em'}} className="button secondary">I agree</button>
        </span>
    </Reveal>;
}

export interface ErrorMessage extends Event {
    detail: {
        message: string
    }
}

const LogoutButton = ({googleApiKey}: {googleApiKey: string | null}) => {
    const signOut = useSignOut(googleApiKey);
    return <button className={'link'} onClick={ () => signOut() }>Log out</button>
};

const UserWidget = () => {

    const context = useContext<Context>(UserContext);
    const settings  = useContext<Settings | undefined>(SettingsContext);

    const user = context.loggedInState.user;

    if (!user || !settings) {
        return <React.Fragment />
    }

    return <div id="player-stats">
        <div className="column row">
            <p>
                <i className="fi-torso"/>&nbsp;
                <ApplicationLink href={ '/users/me' }>{ user.user.user_name } ({ user.user.team_name })</ApplicationLink>
                <LeaderBibs user={user.user} />
                Score: { formatNullableScore(user.score, '-') }&nbsp;
                Ranked: <ApplicationLink href={ '/overall' }>{ formatNullableRank(user.rank, 'N/A') }</ApplicationLink>
                <LogoutButton googleApiKey={ settings.google_api_key }/>
            </p>
        </div>
    </div>;
}

export const Messages = () => {

    const [messages, setMessages] = useState<Message[]>([]);
    const [dismissedMessages, setDismissedMessages] = useState<number[]>(JSON.parse(window.localStorage.getItem('dismissedMessages') || "[]"));

    function saveDismissedMessages(dismissedMessages: number[]) {
        setDismissedMessages(dismissedMessages);
        return window.localStorage.setItem('dismissedMessages', JSON.stringify(dismissedMessages));
    }

    function dismissMessage(messageId: number) {
        const newDismissedMessages = [...dismissedMessages];
        newDismissedMessages.push(messageId);
        saveDismissedMessages(newDismissedMessages);
    }

    const location = useLocation();

    useEffect(() => {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/messages?page=${location.pathname}`,
            success: (messages: Message[]) => {
                setMessages(messages);
            }
        });
    }, [location.pathname]);

    const filteredMessages = useMemo(() => {
        return messages.filter((message) => !dismissedMessages.find((messageId) => message.message_id === messageId))
    }, [messages, dismissedMessages])

    return <div>
        {filteredMessages.map((m, index) => <MessageComponent header={m.header} body={m.text} level={m.level || "primary"} key={index} click={() => dismissMessage(m.message_id)}/>)}
    </div>
}

interface Message {
    message_id: number;
    header: string;
    text: string;
    level: string;
}

const MessageComponent = (props: MessageProperties) => {

    const body = props.body.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    });
    return <div className={`callout ${props.level}`} data-closable="">
        <h5>{props.header}</h5>
        <p>
            {body}
        </p>
        <button className="close-button" aria-label="Dismiss alert" type="button" onClick={props.click} data-close="">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
}

interface MessageProperties {
    body: string;
    header: string;
    level: string;
    click(): void;
}

const ErrorBoundary = (props: React.PropsWithChildren<ErrorProps>) => {
    const context = useContext<ErrorState>(ErrorContext);
    if (context.hasError) {
        return props.errorPage;
    }
    return <Sentry.ErrorBoundary onError={() => context.displayError()}>
        {props.children}
    </Sentry.ErrorBoundary>
}

interface ErrorProps {
    errorPage: React.ReactElement;
}