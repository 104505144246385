import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import { HTTP_CLIENT } from "./Util";
import { Settings } from "./models";

HTTP_CLIENT.get<Settings>("/api/settings")
    .then((response) => {
            if (response.data.sentry_dsn) {
                Sentry.init({
                    dsn: response.data.sentry_dsn,
                    integrations: [
                        // See docs for support of different versions of variation of react router
                        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                        Sentry.reactRouterV6BrowserTracingIntegration({
                            useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes,
                        }),
                        Sentry.replayIntegration(),
                    ],

                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for tracing.
                    // Learn more at
                    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
                    tracesSampleRate: 1.0,

                    tracePropagationTargets: [window.location.protocol + '//' + window.location.host + '/api'],

                    // Capture Replay for 10% of all sessions,
                    // plus for 100% of sessions with an error
                    // Learn more at
                    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                });
            }
        }
    );