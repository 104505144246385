import { SelectionResponse, WeekendResponse } from "./responses"

export interface League {
    password_protected: boolean;
    links: {
        self: string;
        members: string;
    }
    admin_id: string;
    name: string;
    verified: boolean;
}

export interface LeagueScore {
    score: number;
}

export interface Country {
    name: string;
    alpha_3: string;
    alpha_2: string;
}

export interface Race {
    race_id: number;
    country: string;
    date: string;
    discipline?: string;
    distance?: string;
    gender: string;
    name: string;
    scored: boolean;
    include_in_total: boolean;
    selection_id: number;
    weekend_id: number;
    links: {
        weekend: string;
        selection: string;
    };
}

export class Weekend {
    is_upcoming: boolean;
    weekend_name: string;
    weekend_id: number;
    event_start: string;
    event_end: string;
    trading_close: string;
    trading_open: boolean;
    is_tour: boolean;
    team_per_race: boolean;
    selections: Selection[];
    sidebets: Sidebet[];
    hidden: boolean;
    season: number;
    links: {
        prices: string
    }

    constructor(weekendResponse: WeekendResponse, selectionCreator?: (selection: SelectionResponse) => Selection) {
        if (!selectionCreator) {
            selectionCreator = sel => new Selection(sel);
        }
        this.is_upcoming = weekendResponse.is_upcoming;
        this.weekend_name = weekendResponse.weekend_name;
        this.weekend_id = weekendResponse.weekend_id;
        this.event_start = weekendResponse.event_start;
        this.event_end = weekendResponse.event_end;
        this.trading_close = weekendResponse.trading_close;
        this.trading_open = weekendResponse.trading_open;
        this.is_tour = weekendResponse.is_tour;
        this.team_per_race = weekendResponse.team_per_race;
        this.selections = weekendResponse.selections.map(selectionCreator);
        this.sidebets = weekendResponse.sidebets;
        this.hidden = weekendResponse.hidden;
        this.season = weekendResponse.season;
        this.links = weekendResponse.links;
    }

    #hasMatchingRaces(condition: (race: Race) => boolean) {
        return (
            this.selections.some(selection =>
                selection.races.filter(condition).length
            )
        );
    }

    hasGenderRestrictedRaces() {
        return this.#hasMatchingRaces(race => ['m', 'f'].includes(race.gender));
    }

    hasMixedRaces() {
        return this.#hasMatchingRaces(race => race.gender === 'mixed');
    }
}

export interface AthletePrice {
    athlete_id: number;
    weekend_id: number;
    price: number;
}

export interface AthletePopularity {
    athlete: Athlete;
    athlete_id: number;
    picks: number;
    ratio: number;

}

export interface Sidebet {
    description: string;
    value: number;
    sidebet_id: string;
    betting_close: string;
    options: SidebetOption[];
}

export interface RankedLeague {
    league: League;
    score: number;
    rank: number | null;
}

export interface User {
    team_name: string;
    spending_money: number;
    budget: number;
    country: string;
    user_id: number;
    user_name: string;
    flag: string;
    links: {
        overall_ranking: string
    }
}

export interface Athlete {
    athlete_id: number;
    name: string;
    country: string;
    score: number;
    rank: number;
    flag: string;
    gender: 'm' | 'f' | 'mixed';
    price: number;
    is_team: boolean;
}

export interface OwnedAthlete {
    athlete_id: number;
    athlete: Athlete;
    is_team_captain: boolean;
}

export interface PlacedSidebet {
    sidebet_option: SidebetOption;
}

export interface SidebetOption {
    description: string;
    option_id: number;
}

export interface RankedUser {
    user: User;
    rank?: number;
    score?: number;
    user_id: number;
}

export interface WeekendResults {
    users: RankedUser[];
    weekend: WeekendResponse;
}

export interface UserWeekendResult {
    weekend_id: number;
    user_id: string;
    weekend_rank: number;
    score: number;
    weekend_name: string;
}

export interface LeagueWeekendResult {
    score: number | undefined;
    weekend_id: number;
    user_id: number;
}

export interface SelectionAthletes {
    selection: SelectionResponse;
    athletes: OwnedAthlete[];
    athletePopularities: Map<number, number> | undefined;
    allowedToView: boolean;
}

export interface Cup {
    leaders: RankedUser[];
    name: string;
    links: {
        image: string;
    };
}

export interface AthleteResult {
    race_id: number;
    athlete_id: number;
    score: number;
    rank: number;
    links: Map<string, string>;
    athlete: Athlete;
}

export interface OwnedAthlete {
    athlete: Athlete;
    bought_for: number;
}

export interface Settings {
    recaptcha_public_key: string;
    display_name: string;
    mail_address: string;
    analytics_id: string | null;
    google_api_key: string | null;
    current_season: number;
    users_included_in_league_score: number | null;
    login_oauth_provider: string | undefined;
    sentry_dsn: string | undefined;
}

export interface Copy {
    key: string;
    value: string;
}

export interface LeagueWeekendResult {
    selections: LeagueWeekendResultSelection[];
    users: LeagueWeekendResultUser[];
}

export interface LeagueWeekendResultSelection {
    selection_id: number;
    users: LeagueWeekendResultUser[];
}

export interface LeagueWeekendResultUser {
    user_id: number;
    score: number;
    extra_score?: number;
    athletes: LeagueWeekendResultAthlete[];
}

export interface LeagueWeekendResultAthlete {
    id: number;
    score: number;
}

export interface ServerError {
    message?: string;
}

export interface SignInError extends ServerError{
    user_data?: any;
}

export interface FormError {
    response: {
        errors: any,
        field_errors: any
    }
}

export interface ErrorState {
    hasError: boolean;
    displayError: () => void;
}

export interface LoadedUser {
    user: RankedUser;
    usersLeagues: League[];
}

export interface LoadedRaceData {
    race: Race;
    selection: SelectionResponse;
    weekend: WeekendResponse;
}

export interface LeagueWeekendViewLoaderData {
    weekend: WeekendResponse,
    leagueMembers: RankedUser[],
    result: LeagueWeekendResult;
}

export interface LoadedLeague {
    league: League;
    leagueScore: LeagueScore;
    leagueMembers: RankedUser[];
}

export class Selection {

    trading_open: boolean;
    trading_close: string;
    selection_id: number;
    name: string;
    races: Race[];
    max_picks_per_gender: number;
    takes_team_picks: boolean;
    is_coed: boolean;
    is_mixed: boolean;
    team_captains_enabled: boolean;
    links: {
        athlete_popularity: string;
    }

    constructor(selection: SelectionResponse) {
        this.trading_open = selection.trading_open;
        this.trading_close = selection.trading_close;
        this.selection_id = selection.selection_id;
        this.name = selection.name;
        this.races = selection.races;
        this.max_picks_per_gender = selection.max_picks_per_gender;
        this.takes_team_picks = selection.takes_team_picks;
        this.is_coed = selection.is_coed;
        this.is_mixed = selection.is_mixed;
        this.team_captains_enabled = selection.team_captains_enabled;
        this.links = selection.links;
    }

    maxAthletePicks(): number {
        return this.max_picks_per_gender;
    }
}
